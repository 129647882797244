.first_about_container {
    background-image: linear-gradient(358deg, #fff 20%, #ede8de 80%);
    padding: 8rem 6rem 2rem 6rem;
    text-align: center;
    color: #d19e78;
    font-family: 'arboria';
}

.first_about_container h1 {
    font-weight: 900;
    font-size: 3.4rem;
    text-transform: uppercase;
}

.first_about_container p {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 1.3rem;
}

.awards {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title_awards h1 {
    font-size: 2.2rem;
    font-weight: 700;
    color: #d19e78;
    font-family: 'arboria';
    text-transform: uppercase;
    text-decoration: underline;
}

.paths_awards {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.awards img {
    max-width: 20rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.awards .path {
    max-width: 8rem;
}

.awards .ibilogo {
    max-width: 6rem;
}

.second_about_container {
    color: #d19e78;
    font-family: 'arboria';
    text-align: center;
    padding: 2rem 0rem 0rem 0rem;
}

.second_about_container h1 {
    font-weight: 700;
    font-size: 2.8rem;
}

.unit_one {
    display: flex;
    align-items: center;
    color: #d19e78;
    font-family: 'arboria';
    padding: 3rem 3rem 0rem 3rem
}

.unit_textblock {
    padding: 0rem 3rem 0rem 0rem; 
}

.unit_textblock h1 {
    font-weight: 700;
    font-size: 2.6rem;
}

.unit_textblock p {
    font-weight: 400;
    font-size: 1.3rem;
    margin-top: 1rem;
}

.hour_phone {
    margin-top: 2rem;
}

.hour_phone h1 {
    font-weight: 700;
    font-size: 2.3rem;
}

.hour_phone p {
    font-weight: 700;
}

.icon_phone {
    display: flex;
    align-items: center;
    margin-top: 0.3rem;
    margin-top: 0.3rem;
}

.phone i {
    font-size: 1.8rem;
    margin-right: 0.6rem;
    margin-top: 0.6rem;
}

.icon_wpp {
    display: flex;
    align-items: center;
}

.wpp i {
    font-family: "Font Awesome 5 Brands";
    font-size: 2.6rem;
    margin-right: 0.6rem;
    margin-top: 0.6rem;
}

.icon_clock {
    display: flex;
    align-items: center;
}

.icon_clock i {
    font-size: 1.8rem;
    margin-right: 0.6rem;
    margin-top: 0.5rem;
}

.map iframe {
    width: 30rem;
    height: 24rem;
    border: none;
}

.logocontainer {
    display: flex;
    justify-content: center;
}

.logocontainer img {
    max-width: 6rem;
    margin-top: 1rem;
}

.container_slider {
    padding: 1rem 0 1rem 0;
    background-image: linear-gradient(to top, #ede8de, #fff 26%);
}

.swiper_item {
    width: 43rem !important;
    height: 29rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper_item img {
    width: 43rem !important;
    height: 29rem !important;
    object-fit: cover;
    border-radius: 1rem;
}

@media (max-width: 1024px) {
   .swiper_item {
        width: 39rem !important;
        height: 27rem !important;
    }

    .swiper_item img {
        width: 39rem !important;
        height: 27rem !important;
    }
}

@media (max-width: 768px) {
    .swiper_item {
        width: 27rem !important;
        height: 20rem !important;
    }

    .swiper_item img {
        width: 27rem !important;
        height: 20rem !important;
    }
}

@media (max-width: 500px) {
    .swiper_item {
        width: 20rem !important;
        height: 14rem !important;
    }

    .swiper_item img {
        width: 20rem !important;
        height: 14rem !important;
    }
}

@media (max-width: 1115px) and (min-width: 1024px) {
    .unit_one {
        padding: 3rem 2rem 0rem 2rem
    }
}

@media (max-width: 1023px) and (min-width: 768px) {
    .first_about_container {
        padding: 8rem 2rem 2rem 2rem;
    }

    .unit_one {
        flex-direction: column;
        padding: 3rem 2rem 0rem 2rem
    }

    .unit_textblock {
        padding: 0rem 1rem 1rem 0rem; 
    }

    .map iframe {
        width: 25rem;
        height: 19rem;
        margin-bottom: 1rem;
    }
    
}

@media (max-width: 767px) and (min-width: 590px) {
    .first_about_container {
        padding: 8rem 2rem 1rem 2rem;
    }

    .title_awards h1 {
        font-size: 2.4rem;
    }

    .awards img {
        max-width: 17rem;
    }
    
    .awards .path {
        max-width: 7rem;
    }
    
    .awards .ibilogo {
        max-width: 5rem;
    }

    .unit_one {
        flex-direction: column;
        padding: 3rem 2rem 0rem 2rem
    }

    .unit_textblock {
        padding: 0rem 1rem 1rem 0rem; 
    }

    .map iframe {
        width: 25rem;
        height: 19rem;
        margin-bottom: 1rem;
    }
}

@media (max-width: 589px) and (min-width: 429px) {
    .first_about_container {
        text-align: start;
        padding: 8rem 1rem 2rem 1rem;
    }

    .first_about_container h1 {
        font-size: 3rem;
    }

    .first_about_container p {
        font-size: 1.1rem;
        letter-spacing: 0.03rem;
    }

    .first_about_container p:nth-child(3) {
        display: none;
    }

    .title_awards h1 {
        font-size: 2rem;
    }

    .awards img {
        max-width: 13rem;
        margin-left: 0.6rem;
        margin-right: 0.6rem;
    }
    
    .awards .path {
        max-width: 5rem;
    }
    
    .awards .ibilogo {
        max-width: 3.4rem;
    }

    .second_about_container h1 {
        font-size: 2.3rem;
    }

    .unit_one {
        flex-direction: column;
        padding: 3rem 1rem 0rem 1rem
    }

    .unit_textblock {
        padding: 0rem 0rem 2rem 0rem; 
    }

    .unit_textblock h1 {
        font-size: 2rem;
    }

    .unit_textblock p {
       font-size: 1rem;
       letter-spacing: 0.05rem;
    }

    .hour_phone h1 {
        font-size: 2rem;
    }

    .hour_phone .icon_phone .phone i {
        font-size: 1.8rem;
    }

    .hour_phone .icon_wpp .wpp i {
        font-size: 2.3rem;
    }

    .hour_phone .icon_clock .clock i {
        font-size: 1.6rem;
    }

    .map iframe {
        width: 25rem;
        height: 19rem;
        margin-bottom: 1rem;
    }  
}

@media (max-width: 428px) and (min-width: 375px) {
    .first_about_container {
        text-align: start;
        padding: 8rem 1rem 2rem 1rem;
    }

    .first_about_container h1 {
        font-size: 3rem;
    }

    .first_about_container p {
        font-size: 1.1rem;
    }

    .first_about_container p:nth-child(3) {
        display: none;
    }

    .title_awards h1 {
        font-size: 1.8rem;
    }

    .awards img {
        max-width: 11rem;
        margin-left: 0.6rem;
        margin-right: 0.6rem;
    }
    
    .awards .path {
        max-width: 4rem;
    }
    
    .awards .ibilogo {
        max-width: 3rem;
    }

    .second_about_container h1 {
        font-size: 2rem;
        line-height: 2.4rem;
    }

    .unit_one {
        flex-direction: column;
        padding: 3rem 1rem 0rem 1rem
    }

    .unit_textblock {
        padding: 0rem 0rem 2rem 0rem; 
    }

    .unit_textblock h1 {
        font-size: 2rem;
    }

    .unit_textblock p {
       font-size: 1rem;
       letter-spacing: 0.05rem;
    }

    .hour_phone h1 {
        font-size: 2rem;
    }

    .hour_phone .icon_phone .phone i {
        font-size: 1.8rem;
    }

    .hour_phone .icon_wpp .wpp i {
        font-size: 2.3rem;
    }

    .hour_phone .icon_clock .clock i {
        font-size: 1.6rem;
    }

    .map iframe {
        width: 22rem;
        height: 16rem;
        margin-bottom: 1rem;
    }  
}