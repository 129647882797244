.NavbarItems {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    font-size: 0.93rem;
    padding: 0 30px;
    background-image: linear-gradient(349.78deg, #fff -17.21%, #ede8de 116.61%);
    box-shadow: 2px 2px 5px #c9c5bd, -1px -1px 2px #ffffff;
    width: 95%;
    height: 90px;
    border: 1px solid #fff;
    border-radius: 13px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 500;   
}

.containerLogo {
    display: block;
    cursor: pointer;
}

.containerLogo img {
    width: 10rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    list-style:none;
    align-items: center;
    text-align: center;
    justify-content: end;
}

.nav-links {
    text-decoration: none;
    color: #d19e78;
    padding: 0.7rem 1rem;
    white-space: nowrap;
    font-family: 'arboria';
    font-weight: 500;
}

.nav-links:hover {
    background: linear-gradient(-149.78deg, #fff 17.21%, #ede8de 116.61%);
    transition: all 0.2s ease-in-out;
    border-radius: 9px;
}

.fa-bars,
.fa-times {
    color: #cea785;
}

.menu-icons {
    font-size: 1.6rem;
    display: none;
}


@media screen and (max-width:998px) {
    .NavbarItems {
        z-index: 99;
    }
    
    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: linear-gradient(349.78deg, #fff -17.21%, #ede8de 116.61%);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, .37);
        border-radius: 13px 13px;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -110%;
        align-items: stretch;
        padding: 80px 0 0px 0;
        margin: 0;
        z-index:-1;
        transition:all 0.3s ease-in-out;
        grid-gap: 5px;
    }

    .nav-menu.active {
        left: 0%;
    }

    .nav-links{
        display: block;
        widows: 100%;
        padding: 1.6rem 0;
        color: #d19e78;
        border-radius: 0px 0px 13px 13px;
        border-bottom: 1px solid #fff;
        border-top: none;
        border-right: none;
        border-left: none; 
        box-shadow: 0px 1px 0px #c9c5bd, 0px 0px 0px #ffffff;
    }

    .nav-links:nth-child(6){
        box-shadow: none;
    }

    .nav-links:hover {
        background: rgba(255,255,255, 0.35);
        backdrop-filter: blur(20px);
    }

    .menu-icons {
        display: flex;
        justify-content: flex-end;
    }
}