.first_container_services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(358deg, #fff 20%, #ede8de 80%);
    padding: 8rem 6rem 3rem 6rem;
    color: #d19e78;
    font-family: 'arboria';
    text-align: center;
}

.first_container_services h1 {
    font-weight: 700;
    font-size: 2.8rem;
}

.first_container_services p {
    font-weight: 400;
    font-size: 1.3rem;
    padding: 1rem 10rem 0rem 10rem;
}

.second_container_services {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem 0rem 0rem;
}

.benefitspackservices {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0rem 3rem 0rem;
}

.benefitspackservices .benefits_pack {
    font-family: 'arboria';
    position: relative;
    background-image: linear-gradient(349.78deg, #fff -17.21%, #ede8de 116.61%);
    padding: 0.6rem 1.6rem 0.6rem 1.6rem;
    box-shadow: 1px 1px 3px #c9c5bd, -1px -1px 2px #ffffff;
    border-radius: 1rem;
    border: 0.1rem solid rgba(255, 255, 255, 0.2);
    overflow: hidden;
}

.benefitspackservices .benefits_pack:nth-child(2) {
    margin-top: 2rem;
}

.benefitspackservices .benefits_pack input {
    display: none;
}

.benefitspackservices .benefits_pack label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.benefitspackservices .benefits_pack label::after {
    content: '+';
    position: relative;
    left: 0.8rem;
    bottom: 0.3rem;
    font-size: 3rem;
    color: rgba(0, 0, 0, 0.1);
    transition: transform 1s;
}

.benefitspackservices .benefits_pack:hover label::after {
    color: #d19e78;
}

.benefitspackservices .benefits_pack input:checked~label::after {
    transform: rotate(135deg);
    color: #fff;
    top: 0.4rem;
    left: 1rem;
}

.benefitspackservices .benefits_pack label div img {
    width: 7rem;
    height: 3.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    margin-right: 1rem;
    padding: 0.6rem;
    background-image: linear-gradient(349.78deg, #fff -17.21%, #ede8de 116.61%);
    border: 0.1rem solid rgba(255, 255, 255, 0.2);
}

.benefitspackservices .benefits_pack input:checked~label div img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20rem;
    height: 12rem;
    opacity: 0.14;
    justify-content: flex-end;
    background: none;
    border: none;
}

.benefitspackservices .benefits_pack input:checked~label div {
    background-image: linear-gradient(30deg, #cda582 -3.21%, #fff 26%);
}

.benefitspackservices .benefits_pack label h1 {
    position: relative;
    color: #d19e78;
    z-index: 10;
    bottom: 0.1rem;
    font-size: 1.5rem;
    font-weight: 700;
}

.benefitspackservices .benefits_pack .signature_pack {
    max-height: 0;
    transition: 1s;
    overflow: hidden;
}

.benefitspackservices .benefits_pack input:checked~.signature_pack {
    max-height: 100vh;
}

.benefitspackservices .benefits_pack .signature_pack h3 {
    position: relative;
    font-weight: 400;
    font-size: 1.1rem;
    margin-top: 1rem;
    padding: 0.4rem 0;
    color: #d19e78;
    z-index: 10;
}

.benefitspackservices .benefits_pack:first-child .signature_pack h3 {
    line-height: 1rem;
}

.carousel_container {
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    background-image: linear-gradient(to right, #fff 30%, #ede8de);
}

.carousel_container_two {
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    background-image: linear-gradient(to left, #fff 30%, #ede8de);
    margin-top: 2rem;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes scrolltwo {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0%);
    }
}

.slider_carousel {
    display: inline-flex;
    animation: scroll 25s linear infinite;
    padding: 2rem 0rem;
}

.slider_carousel_two {
    display: inline-flex;
    animation: scrolltwo 25s linear infinite;
    padding: 2rem 0rem;
}

.item_carousel h1 {
    color: #d19e78;
    font-family: 'arboria';
    margin: 0 2rem;
    font-weight: 400;
    font-size: 2.3rem;
}

.buttonscheduling {
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonscheduling button {
    background: linear-gradient(349.78deg, #fff -17.21%, #ede8de 116.61%);
    color: #d19e78;
    width: 290px;
    height: 60px;
    border: 1px solid #fff;
    font-family: 'arboria';
    font-size: 1.2rem;
    border-radius: 1rem;
    cursor: pointer;
    font-weight: 400;
    box-shadow: 1px 1px 3px #c9c5bd, -1px -1px 2px #ffffff;
}


@media (max-width: 1024px) and (min-width: 768px) {
    .first_container_services {
        padding: 9rem 2rem 3rem 2rem;
    }

    .first_container_services p {
        padding: 1rem 2rem 0rem 2rem;
    }
}

@media (max-width: 767px) and (min-width: 590px) {
    .first_container_services {
        padding: 9rem 2rem 3rem 2rem;
    }

    .first_container_services p {
        padding: 1rem 2rem 0rem 2rem;
    }
}

@media (max-width: 589px) and (min-width: 429px) {
    .first_container_services {
        padding: 9rem 1rem 3rem 1rem;
        text-align: start;
    }

    .first_container_services h1 {
        font-size: 2rem;
    }

    .first_container_services p {
        padding: 1rem 0rem 0rem 0rem;
        font-size: 1.2rem;
    }

    .benefitspackservices {
        padding: 1rem 1rem 3rem 1rem;
    }

}

@media (max-width: 428px) and (min-width: 375px) {
    .first_container_services {
        padding: 8rem 1rem 3rem 1rem;
        text-align: start;
    }

    .first_container_services h1 {
        font-size: 2rem;
    }

    .first_container_services p {
        padding: 1rem 0rem 0rem 0rem;
        font-size: 1.2rem;
    }

    .benefitspackservices {
        padding: 1rem 1rem 3rem 1rem;
    }

}