.title_coursescontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(360deg, #fff 20%, #ede8de 80%);
    padding: 8rem 12rem 2rem 12rem;
}

.title_coursescontainer {
    font-family: 'arboria';
    color: #d19e78;
    text-align: center;
}

.title_coursescontainer h1 {
    margin-top: 1.6rem;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.6rem;
}

.title_coursescontainer img {
    max-width: 16rem;
}

.block_course {
    color: #d19e78;
    font-family: 'arboria';
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0rem 3rem 0rem 3rem;
}

.block_course_text_one p {
    margin-top: 2rem;
    font-size: 1.3rem;
}

.block_course h2 {
    font-size: 1.8rem;
    margin-bottom: 2rem;
}

.block_course q {
    font-size: 1.2rem;
    font-style: italic;
}

.block_course_text {
    text-align: end;
}

.block_course_text h3 {
    margin-bottom: 2rem;
    font-weight: 500;
    text-decoration: underline;
    font-size: 1.4rem;
}

.block_course_text p {
    font-weight: 400;
    font-size: 1.3rem;
    margin-top: 1rem;
}

.block_course_text i {
    font-size: 3rem;
    margin-top: 2rem;
}

.block_course img {
    max-width: 18rem;
}

.prodcourse_container {
    display: flex;
    align-items: center;
    background-image: linear-gradient(190deg, #ede8de, #fff 26%);
    color: #d19e78;
    font-family: 'arboria';
    padding: 3rem 3rem 1rem 3rem;
}

.prodcourse_container img {
    max-width: 400px;
    border-radius: 1rem;
}

.block_prod {
    margin-right: 4rem;
}

.block_prod h1 {
    font-size: 2.4rem;
    line-height: 2.6rem;
    margin-bottom: 1.6rem;
}

.block_prod p {
    font-weight: 400;
    font-size: 1.3rem;
    margin-top: 1rem;
}

.block_prod button {
    background: linear-gradient(349.78deg, #fff 17.21%, #ede8de 116.61%);
    color: #d19e78;
    width: 11rem;
    height: 3.2rem;
    border: 1px solid #fff;
    font-family: 'arboria';
    font-size: 1.1rem;
    border-radius: 1rem;
    cursor: pointer;
    font-weight: 400;
    margin-top: 2rem;
    box-shadow: 1px 1px 3px #c9c5bd, -12px -12px 24px #ffffff;
}

.gif_block_prod img {
    width: 20rem;
}

.prodcourse_containertwo {
    background-image: linear-gradient(to bottom, #fff, #ede8de 6%);
    font-family: 'arboria';
    color: #d19e78;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 4rem;
}

.prodcourse_containertwo button {
    background: linear-gradient(349.78deg, #fff 17.21%, #ede8de 116.61%);
    color: #d19e78;
    width: 16rem;
    height: 3.2rem;
    line-height: 0;
    border: 1px solid #fff;
    font-family: 'arboria';
    font-size: 1.1rem;
    border-radius: 1rem;
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 2rem;
    box-shadow: 1px 1px 3px #c9c5bd, -12px -12px 44px #ffffff;
}

.title_prodcourse_containertwo {
    font-size: 1rem;
    text-align: center;
    padding: 3rem 9rem 3rem 9rem;
}

.title_prodcourse_containertwo i {
    font-size: 3rem;
    display: none;
}

.title_prodcourse_containertwo img {
    width: 16rem;
    display: none;
    margin-bottom: 1rem;
}

.title_prodcourse_containertwo h2 {
    font-weight: 500;
}

.prodcourse_containertwo .video_block {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prodcourse_containertwo .video_block .video_block_model img {
    width: 20rem;
    border-radius: 1rem;
}

.prodcourse_containertwo .video_block .video_block_model2 img {
    width: 20rem;
    border-radius: 1rem;
}

.prodcourse_containertwo .video_block .video_block_text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prodcourse_containertwo .video_block .video_block_text img {
    width: 16rem;
    margin-bottom: 2rem;
}

.prodcourse_containertwo .video_block .video_block_text i {
    font-size: 3rem;
}

.prodcourse_containertwo .video_block .video_block_text i:nth-child(1) {
    margin-bottom: 0.6rem;
}

.prodcourse_containertwo .video_block .video_block_text h3 {
    font-weight: 500;
    text-align: center;
    padding: 1rem 4rem 2rem 4rem;
}

.prodcourse_containertwo .box_button {
    padding: 2rem 0rem 0rem 0rem;
}

.prodcourse_containertwo .box_button h3 {
    display: none;
}

.prodcourse_containertwo .box_button i {
    margin-top: 2rem;
    display: none;
}

.prodcourse_containerthree {
    background-image: linear-gradient(to bottom, #ede8de, #fff 6%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 0rem 2rem 0rem;
    font-family: 'arboria';
    color: #d19e78;
}

.prodcourse_containerthree button {
    background: linear-gradient(349.78deg, #fff 17.21%, #ede8de 116.61%);
    color: #d19e78;
    width: 16rem;
    height: 3.2rem;
    line-height: 0;
    border: 1px solid #fff;
    font-family: 'arboria';
    font-size: 1.1rem;
    border-radius: 1rem;
    cursor: pointer;
    font-weight: 400;
    margin-top: 2rem;
    box-shadow: 1px 1px 3px #c9c5bd, -12px -12px 44px #ffffff;
}

.title_prodcourse_containerthree h1 {
    font-size: 2.4rem;
    line-height: 2.6rem;
    padding: 2rem 12rem 3rem 12rem;
    text-align: center;
}

.block_images img {
    max-width: 16rem;
    border-radius: 1rem;
}

.block_images img:nth-child(2) {
    margin-left: 2rem;
}

.block_images img:nth-child(3) {
    margin-left: 2rem;
    margin-right: 2rem;
}

.subtitle_prodcourse_containerthree {
    text-align: center;
    padding: 3rem 12rem 0rem 12rem;
}

.subtitle_prodcourse_containerthree h2 {
    font-size: 2.4rem;
}

.subtitle_prodcourse_containerthree i {
    margin-top: 1rem;
    font-size: 3rem;
}

.subtitle_prodcourse_containerthree h3 {
    font-weight: 400;
    margin-top: 1rem;
    font-size: 1.5rem;
    padding: 0 9rem;
}

.items_course_box {
    display: flex;
    padding: 0rem 3rem;
}

.items_course_boxtwo {
    margin-left: 2rem;
}

.items_course {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding: 0rem 6rem 0rem 1.3rem;
    background-image: linear-gradient(110deg, #ede8de, #fff 9%);
    border: 1px solid hsla(0, 0%, 100%, .01);
}

.items_course h2 {
    margin-top: 0.6rem;
    text-decoration: underline;
}

.items_course p {
    line-height: 1.3rem;
    padding: 0.6rem 0;
}

.items_course ul {
    font-weight: 300;
    line-height: 1.6rem;
    margin-left: 1rem;
}

.block_prod_end {
    display: flex;
    align-items: center;
    padding: 0rem 3rem 0rem 3rem;
    color: #d19e78;
    font-family: 'arboria';
    font-size: 1.3rem;
}

.block_prod_end p {
    margin-top: 2rem;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.6rem;
}

.block_prod_end img {
    max-width: 23rem;
    border-top-left-radius: 10rem;
    border-bottom-right-radius: 5rem;
    margin-right: 3rem;
}

@media (max-width: 1115px) and (min-width: 1024px) {
    .title_prodcourse_containertwo {
        padding: 3rem 2rem 3rem 2rem;
    }

    .prodcourse_containertwo {
        padding: 0 2rem;
    }

    .prodcourse_containertwo .video_block .video_block_text h3 {
        padding: 1rem 1rem 2rem 1rem;
    }

    .block_images img {
        max-width: 13rem;
    }
}

@media (max-width: 1023px) and (min-width: 768px) {
    .title_coursescontainer {
        padding: 8rem 3rem 2rem 3rem;
    }

    .block_course {
        display: flex;
        flex-direction: column;
        padding: 0rem 3rem 1rem 3rem;
        text-align: center;
    }

    .block_course img {
        margin-top: 1rem;
    }

    .block_course_text {
        margin-top: 0rem;
    }

    .prodcourse_container {
        flex-direction: column-reverse;
        padding: 3rem 3rem 1rem 3rem;
    }

    .block_prod {
        margin-right: 0rem;
        margin-top: 1rem;
    }

    .title_prodcourse_containertwo {
        margin-bottom: 0rem;
        padding: 0rem 3rem 0rem 3rem;
    }

    .title_prodcourse_containertwo img {
        display: block;
        margin-bottom: 2rem;
    }

    .title_prodcourse_containertwo i {
        display: block;
        margin-top: 1rem;
    }

    .title_prodcourse_containertwo h2 {
        display: none;
    }

    .prodcourse_containertwo .video_block {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .prodcourse_containertwo .video_block .video_block_text {
        margin-bottom: 2rem;
    }

    .prodcourse_containertwo .video_block .video_block_text i:nth-child(1) {
        display: none;
    }

    .prodcourse_containertwo .video_block .video_block_text img {
        display: none;
    }

    .prodcourse_containertwo .video_block .video_block_text i:nth-child(3) {
        padding: 1rem 0rem 1rem 0rem;
    }

    .prodcourse_containertwo .video_block .video_block_text h3:nth-child(4) {
        padding: 0rem 5rem 0rem 5rem;
    }

    .prodcourse_containertwo .video_block .video_block_text i:nth-child(5) {
        display: none;
    }

    .prodcourse_containertwo .video_block .video_block_text h3:nth-child(6) {
        display: none;
    }

    .prodcourse_containertwo .box_button {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0rem;
    }

    .prodcourse_containertwo .box_button i {
        font-size: 3rem;
        display: block;
        text-align: center;
        margin-top: 2rem;
    }

    .prodcourse_containertwo .box_button h3 {
        font-weight: 500;
        padding: 1rem 2rem 2rem 2rem;
        display: block;
    }

    .title_prodcourse_containerthree {
        padding: 0rem 3rem 0rem 3rem;
    }

    .title_prodcourse_containerthree h1 {
        padding: 2rem 1rem 3rem 1rem;
    }

    .block_images {
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: relative;
        left: 1rem;
    }

    .block_images img:nth-child(2) {
        margin-left: 0rem;
    }

    .block_images img:nth-child(3) {
        margin-left: 0rem;
        margin-top: 2rem;
    }

    .block_images img:nth-child(4) {
        margin-top: 2rem;
    }

    .subtitle_prodcourse_containerthree {
        padding: 2rem 3rem 0rem 3rem;
    }

    .prodcourse_containerthree h3 {
        font-size: 1.3rem;
        padding: 0rem 2rem 0rem 2rem;
    }

    .items_course_box {
        flex-direction: column;
        padding: 0rem 2rem;
    }

    .items_course_boxtwo {
        margin-left: 0rem;
    }

    .block_prod_end {
        flex-direction: column;
        text-align: center;
    }

    .block_prod_endtext {
        margin-top: 1rem;
    }

}

@media (max-width: 768px) and (min-width: 591px) {
    .title_coursescontainer {
        padding: 8rem 1rem 2rem 1rem;
    }

    .block_course {
        display: flex;
        flex-direction: column;
        padding: 0rem 2rem 1rem 2rem;
        text-align: center;
    }

    .block_course img {
        margin-top: 1rem;
    }

    .block_course_text {
        text-align: center;
        margin-top: 0rem;
    }

    .prodcourse_container {
        flex-direction: column-reverse;
        padding: 3rem 2rem 1rem 2rem;
    }

    .block_prod {
        margin-right: 0rem;
        margin-top: 1rem;
    }

    .block_prod_text {
        margin-right: 0rem;
        margin-top: 2rem;
    }

    .title_prodcourse_containertwo {
        padding: 0rem 3rem 0rem 3rem;
    }

    .title_prodcourse_containertwo h2 {
        display: none;
    }

    .title_prodcourse_containertwo i {
        display: block;
        margin-bottom: 0.6rem;
    }

    .title_prodcourse_containertwo img {
        display: block;
        margin-bottom: 2rem;
    }

    .prodcourse_containertwo .video_block {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .prodcourse_containertwo .video_block .video_block_text {
        margin-bottom: 2rem;
        margin-top: 0rem;
    }

    .prodcourse_containertwo .video_block .video_block_text i:nth-child(1) {
        display: none;
    }

    .prodcourse_containertwo .video_block .video_block_text img {
        display: none;
    }

    .prodcourse_containertwo .video_block .video_block_text i:nth-child(3) {
        padding: 1rem 0rem 0rem 0rem;
    }

    .prodcourse_containertwo .video_block .video_block_text h3:nth-child(4) {
        padding: 1rem 3rem 0rem 3rem;
    }

    .prodcourse_containertwo .video_block .video_block_text i:nth-child(5) {
        display: none;
    }

    .prodcourse_containertwo .video_block .video_block_text h3:nth-child(6) {
        display: none;
    }

    .prodcourse_containertwo .box_button {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0rem;
    }

    .prodcourse_containertwo .box_button i {
        font-size: 3rem;
        display: block;
        text-align: center;
        margin-top: 2rem;
    }

    .prodcourse_containertwo .box_button h3 {
        font-weight: 500;
        padding: 1rem 2rem 2rem 2rem;
        display: block;
    }

    .title_prodcourse_containerthree h1 {
        padding: 1rem 2rem 2rem 2rem;
    }

    .subtitle_prodcourse_containerthree {
        padding: 2rem 2rem 0rem 2rem;
    }

    .block_images {
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: relative;
        left: 1rem;
    }

    .block_images img {
        max-width: 14rem;
    }

    .block_images img:nth-child(2) {
        margin-left: 0rem;
    }

    .block_images img:nth-child(3) {
        margin-left: 0rem;
        margin-top: 2rem;
    }

    .block_images img:nth-child(4) {
        margin-top: 2rem;
    }

    .items_course_box {
        flex-direction: column;
        padding: 0rem 2rem;
    }

    .items_course_boxtwo {
        margin-left: 0rem;
    }

    .prodcourse_containerthree h3 {
        font-size: 1.3rem;
        font-weight: 500;
        padding: 0rem 2rem 0rem 2rem;
    }

    .prodcourse_containerthree button {
        margin-top: 0rem;
    }

    .block_prod_end {
        flex-direction: column;
        text-align: center;
    }

    .block_prod_endtext {
        margin-top: 1rem;
    }

}

@media (max-width: 590px) and (min-width: 429px) {
    .title_coursescontainer {
        padding: 8rem 1rem 1rem 1rem;
    }

    .title_coursescontainer h1 {
        display: none;
    }

    .block_course {
        display: flex;
        flex-direction: column;
        padding: 0.3rem 1rem 1rem 1rem;
        text-align: center;
    }

    .block_course_text_one h2 {
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }

    .block_course_text_one q {
        font-size: 1.1rem;
    }

    .block_course img {
        margin-top: 1rem;
    }

    .block_course_text {
        text-align: center;
    }

    .block_course_text h3 {
        margin-top: 0.6rem;
        margin-bottom: 1.6rem;
    }

    .block_course_text p {
        font-size: 1.2rem;
    }

    .prodcourse_container {
        flex-direction: column-reverse;
        padding: 3rem 1rem 1rem 1rem;
    }

    .block_prod {
        margin-right: 0rem;
        margin-top: 1rem;
    }

    .block_prod h1 {
        font-size: 2rem;
        line-height: 2.2rem;
    }

    .block_prod p {
        font-size: 1.2rem;
    }

    .block_prod p:nth-child(3) {
        display: none;
    }

    .prodcourse_containertwo {
        padding: 2rem 1rem 0rem 1rem;
    }

    .title_prodcourse_containertwo h2 {
        display: none;
    }

    .title_prodcourse_containertwo i {
        display: block;
    }

    .title_prodcourse_containertwo img {
        display: block;
        margin-bottom: 2rem;
    }

    .title_prodcourse_containertwo {
        margin-bottom: 0rem;
        padding: 0rem 1rem 0rem 1rem;
    }

    .prodcourse_containertwo .video_block {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .prodcourse_containertwo .video_block .video_block_text {
        margin-bottom: 2rem;
    }

    .prodcourse_containertwo .video_block .video_block_text h3 {
        padding: 0rem;
    }

    .prodcourse_containertwo .video_block .video_block_text i:nth-child(1) {
        display: none;
    }

    .prodcourse_containertwo .video_block .video_block_text img {
        display: none;
    }

    .prodcourse_containertwo .video_block .video_block_text i:nth-child(3) {
        padding: 1rem 0rem 1rem 0rem;
    }

    .prodcourse_containertwo .video_block .video_block_text h3:nth-child(4) {
        padding: 0rem 3rem 0rem 3rem;
    }

    .prodcourse_containertwo .video_block .video_block_text i:nth-child(5) {
        display: none;
    }

    .prodcourse_containertwo .video_block .video_block_text h3:nth-child(6) {
        display: none;
    }

    .prodcourse_containertwo .box_button {
        text-align: center;
        padding: 0rem;
    }

    .prodcourse_containertwo .box_button i {
        font-size: 3rem;
        display: block;
        text-align: center;
        margin-top: 2rem;
    }

    .prodcourse_containertwo .box_button h3 {
        font-weight: 500;
        padding: 1rem 2rem 2rem 2rem;
        display: block;
    }

    .prodcourse_containerthree {
        padding: 0rem 1rem 0rem 1rem;
    }

    .title_prodcourse_containerthree h1 {
        font-size: 2rem;
        line-height: 2.2rem;
        padding: 2rem 0rem 3rem 0rem;
    }

    .block_images {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .block_images img {
        max-width: 11rem;
    }

    .block_images img:nth-child(2) {
        margin-left: 0rem;
    }

    .block_images img:nth-child(3) {
        margin-left: 0rem;
        margin-top: 2rem;
    }

    .block_images img:nth-child(4) {
        margin-top: 2rem;
    }

    .subtitle_prodcourse_containerthree {
        padding: 2rem 1rem 0rem 1rem;
    }

    .items_course_box {
        flex-direction: column;
        padding: 0rem;
    }

    .items_course_boxtwo {
        margin-left: 0rem;
    }

    .prodcourse_containerthree h3 {
        font-size: 1.2rem;
        font-weight: 500;
        padding: 0rem;
    }

    .prodcourse_containerthree button {
        margin-top: 2rem;
    }

    .block_prod_end {
        flex-direction: column;
        text-align: center;
        padding: 2rem 1rem 0rem 1rem;
    }

    .block_prod_end img {
        position: relative;
        left: 1.3rem;
    }

    .block_prod_endtext {
        margin-top: 1rem;
    }

    .block_prod_endtext p {
        font-size: 1.2rem;
    }

}

@media (max-width: 428px) and (min-width: 375px) {
    .title_coursescontainer {
        padding: 8rem 1rem 1rem 1rem;
    }

    .title_coursescontainer h1 {
        display: none;
    }

    .block_course {
        display: flex;
        flex-direction: column;
        padding: 0.3rem 1rem 1rem 1rem;
        text-align: center;
    }

    .block_course_text_one h2 {
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }

    .block_course_text_one q {
        font-size: 1.1rem;
    }

    .block_course img {
        margin-top: 1rem;
        max-width: 17rem;
    }

    .block_course_text {
        text-align: center;
    }

    .block_course_text h3 {
        margin-top: 0.6rem;
        margin-bottom: 1.6rem;
    }

    .block_course_text p {
        font-size: 1.2rem;
    }

    .prodcourse_container {
        flex-direction: column-reverse;
        padding: 3rem 1rem 1rem 1rem;
    }

    .block_prod {
        margin-right: 0rem;
        margin-top: 1rem;
    }

    .block_prod h1 {
        font-size: 2rem;
        line-height: 2.2rem;
    }

    .block_prod p {
        font-size: 1.2rem;
    }

    .block_prod p:nth-child(3) {
        display: none;
    }

    .prodcourse_containertwo {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 2rem 0rem 0rem 0rem;
    }

    .title_prodcourse_containertwo h2 {
        display: none;
    }

    .title_prodcourse_containertwo i {
        margin-bottom: 0.6rem;
        display: block;
    }

    .title_prodcourse_containertwo img {
        display: block;
        margin-bottom: 2rem;
    }

    .title_prodcourse_containertwo {
        padding: 0rem;
    }

    .prodcourse_containertwo .video_block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0rem;
    }

    .prodcourse_containertwo .video_block_text {
        margin-bottom: 2rem;
    }

    .prodcourse_containertwo .video_block .video_block_text i:nth-child(1) {
        display: none;
    }

    .prodcourse_containertwo .video_block .video_block_text img {
        display: none;
    }

    .prodcourse_containertwo .video_block .video_block_text i:nth-child(3) {
        padding: 1rem 0rem 1rem 0rem;
    }

    .prodcourse_containertwo .video_block .video_block_text i:nth-child(5) {
        display: none;
    }

    .prodcourse_containertwo .video_block .video_block_text h3:nth-child(6) {
        display: none;
    }

    .prodcourse_containertwo .video_block .video_block_text h3 {
        padding: 0rem 3rem 0rem 3rem;
    }

    .prodcourse_containertwo .box_button {
        padding: 0rem;
    }

    .prodcourse_containertwo .box_button h3 {
        font-weight: 500;
        padding: 1rem 2rem 2rem 2rem;
        display: block;
    }

    .prodcourse_containertwo .box_button i {
        margin-top: 2rem;
        font-size: 3rem;
        display: block;
    }

    .prodcourse_containerthree {
        padding: 0rem 1rem 0rem 1rem;
    }

    .title_prodcourse_containerthree h1 {
        font-size: 2rem;
        line-height: 2.2rem;
        padding: 2rem 0rem 3rem 0rem;
    }

    .block_images {
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: relative;
    }

    .block_images img {
        max-width: 10rem;
    }

    .block_images img:nth-child(2) {
        margin-left: 0rem;
    }

    .block_images img:nth-child(3) {
        margin-left: 0rem;
        margin-top: 2rem;
    }

    .block_images img:nth-child(4) {
        margin-top: 2rem;
    }

    .subtitle_prodcourse_containerthree {
        padding: 2rem 0rem 0rem 0rem;
    }

    .subtitle_prodcourse_containerthree h3 {
        font-weight: 500;
        margin-top: 1rem;
        font-size: 1.3rem;
        padding: 0rem;
    }

    .prodcourse_containerthree button {
        margin-top: 2rem;
    }

    .items_course {
        padding: 0rem 0.2rem 0rem 0.6rem;
    }

    .items_course h2 {
        font-size: 1.3rem;
    }

    .items_course_box {
        flex-direction: column;
        padding: 0rem;
    }

    .items_course_boxtwo {
        margin-left: 0rem;
    }

    .block_prod_end {
        flex-direction: column;
        text-align: center;
        padding: 2rem 1rem 0rem 1rem;
    }

    .block_prod_end img {
        max-width: 22rem;
        margin-right: 0rem;
        margin-bottom: 2rem;
    }

    .block_prod_endtext h2 {
        font-size: 1.8rem;
    }

    .block_prod_endtext p {
        font-size: 1.2rem;
    }
}