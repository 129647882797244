.firstcontainer_products {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(360deg, #fff 20%, #ede8de 80%);
    padding: 8rem 6rem 3rem 6rem;
    font-family: 'arboria';
    color: #d19e78;
    text-align: center;
}

.firstcontainer_products h1 {
    font-weight: 700;
    font-size: 2.8rem;
}

.firstcontainer_products p {
    font-weight: 400;
    font-size: 1.3rem;
    margin-top: 1rem;
}

.logo_container_products {
    display: flex;
    justify-content: center;
}

.logo_container_products img {
    width: 14rem;
}

.productlayout {
    display: flex;
    align-items: center;
    background-image: radial-gradient(circle at bottom right, #ede8de 21%, #ffffff 43%);
    padding: 1rem 5rem 0rem 5rem;
}

.text_blockproducts {
    display: flex;
    flex-direction: column;
    font-family: 'arboria';
    color: #ebcb88;
    margin-right: 6rem;
}

.text_blockproducts h1 {
    font-weight: 500;
    font-size: 2.8rem;
    margin-bottom: 1rem;
}

.text_blockproducts p {
    font-weight: 400;
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

.button_product button {
    background: none;
    color: #ebcb88;
    width: 11rem;
    height: 3.4rem;
    border: 1px solid #ebcb88;
    font-family: 'arboria';
    font-size: 1.2rem;
    border-radius: 1rem;
    cursor: pointer;
    font-weight: 400;
    padding: 0rem 0rem 0.2rem 0rem;
}

.product_img img {
    width: 23rem;
}

.product_img {
    display: flex;
    justify-content:center;
}

.productlayout_second {
    display: flex;
    align-items: center;
    background-image: radial-gradient(circle at bottom left, #ede8de 21%, #ffffff 43%);
    padding: 3rem 5rem 0rem 5rem;
}

.text_blockproducts_second {
    display: flex;
    flex-direction: column;
    font-family: 'arboria';
    color: #ebcb88;
    margin-left: 6rem;
}

.text_blockproducts_second h1 {
    font-size: 2.8rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.text_blockproducts_second p {
    font-size: 1.3rem;
    font-weight: 400;
    margin-bottom: 2rem;
}

.button_product_second button {
    background: none;
    color: #ebcb88;
    width: 11rem;
    height: 3.4rem;
    border: 1px solid #ebcb88;
    font-family: 'arboria';
    font-size: 1.2rem;
    border-radius: 1rem;
    cursor: pointer;
    font-weight: 400;
    padding: 0rem 0rem 0.2rem 0rem;
}

.product2_img {
    display: flex;
    justify-content: center;
}

.product2_img img {
    width: 25rem;
}

.productlayout_third {
    display: flex;
    align-items: center;
    background-image: radial-gradient(circle at bottom right, #ede8de 21%, #ffffff 43%);
    padding: 5rem 5rem 0rem 5rem;
}

.text_blockproducts_third {
    display: flex;
    flex-direction: column;
    font-family: 'arboria';
    color: #ebcb88;
}

.text_blockproducts_third h1 {
    font-size: 2.8rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.text_blockproducts_third p {
    font-weight: 400;
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

.button_product_third button {
    background: none;
    color: #ebcb88;
    width: 11rem;
    height: 3.4rem;
    border: 1px solid #ebcb88;
    font-family: 'arboria';
    font-size: 1.2rem;
    border-radius: 1rem;
    cursor: pointer;
    font-weight: 400;
    padding: 0rem 0rem 0.2rem 0rem;
}

.product3_img {
    display: flex;
    justify-content: center;
}

.product3_img img {
    width: 30rem;
}

.productlayout_fifth {
    display: flex;
    align-items: center;
    background-image: radial-gradient(circle at bottom left, #ede8de 21%, #ffffff 43%);
    padding: 5rem 5rem 0rem 5rem;
}

.text_blockproducts_fifth {
    display: flex;
    flex-direction: column;
    font-family: 'arboria';
    color: #ebcb88;
    margin-left: 2rem;
}

.text_blockproducts_fifth h1 {
    font-size: 2.8rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.text_blockproducts_fifth p {
    font-size: 1.3rem;
    font-weight: 400;
    margin-bottom: 2rem;
}

.product5_img {
    display: flex;
    justify-content: center;
}

.product5_img img {
    width: 30rem;
}

.button_product_fifth button {
    background: none;
    color: #ebcb88;
    width: 15rem;
    height: 3.4rem;
    border: 1px solid #ebcb88;
    font-family: 'arboria';
    font-size: 1.2rem;
    border-radius: 1rem;
    cursor: pointer;
    font-weight: 400;
    padding: 0rem 0rem 0.2rem 0rem;
}

.title_sectionformen {
    display: flex;
    text-align: center;
    justify-content: center;
    font-family: 'arboria';
    padding: 5rem 6rem 0rem 6rem;
    color: #302525;
    background-image: radial-gradient(ellipse at top left,#302525 ,#fff  39%);
}

.title_sectionformen h1 {
    font-weight: 700;
    font-size: 2.8rem;
}

.productlayout_fourth {
    display: flex;
    align-items: center;
    background-image: radial-gradient(ellipse at bottom right, #302525,#fff 36%);
    padding: 3rem 5rem 0rem 5rem;
}

.text_blockproducts_fourth {
    display: flex;
    flex-direction: column;
    font-family: 'arboria';
    color: #302525;
    margin-left: 2rem;
}

.text_blockproducts_fourth h1 {
    font-weight: 500;
    font-size: 2.8rem;
    margin-bottom: 1rem;
}

.text_blockproducts_fourth p {
    font-weight: 400;
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

.button_product_fourth button {
    background: none;
    color: #211519;
    width: 14rem;
    height: 3.4rem;
    border: 1px solid #211519;
    font-family: 'arboria';
    font-size: 1.2rem;
    border-radius: 1rem;
    cursor: pointer;
    font-weight: 400;
    padding: 0rem 0rem 0.2rem 0rem;
}

.product4_img {
    display: flex;
    justify-content: center;
}

.product4_img img {
    width: 34rem;
}

.productlayout_eight {
    display: flex;
    align-items: center;
    background-image: radial-gradient(ellipse at top right,#302525 ,#fff 51%);
    padding: 5rem 5rem 0rem 5rem;
}

.text_blockproducts_eight {
    display: flex;
    flex-direction: column;
    font-family: 'arboria';
    color: #302525;
}

.text_blockproducts_eight h1 {
    font-size: 2.8rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.text_blockproducts_eight p {
    font-weight: 400;
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

.product8_img img {
    max-width: 36rem;
}

.logo_container_productsformen {
    display: flex;
    justify-content: center;
    padding:3rem 0rem 0rem 0rem;

}

.logo_container_productsformen img {
    width: 16rem;
}

.product6_img img {
    max-width: 25rem;
}

.product7_img img {
    max-width: 20rem;
}


@media (max-width: 1115px) and (min-width: 1024px) {
    .text_blockproducts {
        margin-right: 0rem;
    }
    
    .text_blockproducts_second {
        margin-left: 3rem;
    }

    .productlayout_fifth {
        padding: 5rem 2rem 0rem 2rem;
    }
    
    .productlayout_fourth {
        padding: 3rem 2rem 0rem 2rem;
    }

    .text_blockproducts_fourth {
        margin-left: 1rem;
    }

    .productlayout_eight {
        padding: 3rem 2rem 0rem 2rem;
    }
}

@media (max-width: 1023px) and (min-width: 768px) {
    .firstcontainer_products {
        padding: 8rem 3rem 3rem 3rem;
    }

    .productlayout {
        flex-direction: column-reverse;
        padding: 1rem 2rem 1rem 2rem;
    }

    .productlayout_second {
        flex-direction: column;
        padding: 3rem 2rem 1rem 2rem;
    }

    .text_blockproducts_second {
        margin-left: 0rem;
    }

    .productlayout_third {
        flex-direction: column-reverse;
        padding: 3rem 2rem 1rem 2rem;
    }
    
    .productlayout_fifth {
        flex-direction: column;
        padding: 3rem 2rem 1rem 2rem;
    }

    .text_blockproducts_fifth {
        margin-left: 0rem;
    }

    .productlayout_fourth {
        flex-direction: column;
        padding: 3rem 2rem 1rem 2rem;
    }

    .productlayout_eight {
        flex-direction: column-reverse;
    }  
}

@media (max-width: 767px) and (min-width: 590px) {
    .firstcontainer_products {
        padding: 8rem 3rem 3rem 3rem;
    }

    .productlayout {
        flex-direction: column-reverse;
        padding: 1rem 2rem 1rem 2rem;
    }

    .productlayout_second {
        flex-direction: column;
        padding: 3rem 2rem 1rem 2rem;
    }

    .text_blockproducts_second {
        margin-left: 0rem;
    }

    .productlayout_third {
        flex-direction: column-reverse;
        padding: 3rem 2rem 1rem 2rem;
    }
    
    .productlayout_fifth {
        flex-direction: column;
        padding: 3rem 2rem 1rem 2rem;
    }

    .text_blockproducts_fifth {
        margin-left: 0rem;
    }

    .productlayout_fourth {
        flex-direction: column;
        padding: 3rem 2rem 1rem 2rem;
    }

    .productlayout_eight {
        flex-direction: column-reverse;
    }    
}

@media (max-width: 589px) and (min-width: 429px) {
    .firstcontainer_products {
        padding: 8rem 1rem 3rem 1rem;
    }

    .firstcontainer_products h1 {
        font-size: 2.4rem;
        line-height: 2.6rem;
    }

    .productlayout {
        flex-direction: column-reverse;
        padding: 1rem;
    }

    .text_blockproducts {
        margin-right: 0rem;
    }

    .productlayout_second {
        flex-direction: column;
        padding: 3rem 1rem 1rem 1rem;
    }

    .text_blockproducts_second {
        margin-left: 0rem;
    }

    .productlayout_third {
        flex-direction: column-reverse;
        padding: 3rem 1rem 1rem 1rem;
    }
    
    .productlayout_fifth {
        flex-direction: column;
        padding: 3rem 1rem 1rem 1rem;
    }

    .text_blockproducts_fifth {
        margin-left: 0rem;
    }

    .productlayout_fourth {
        flex-direction: column;
        padding: 3rem 1rem 1rem 1rem;
    }

    .text_blockproducts_fourth {
        margin-left: 0rem;
    }

    .productlayout_eight {
        padding: 5rem 1rem 0rem 1rem;
        flex-direction: column-reverse;
    }
    
    .title_sectionformen {
        padding: 4rem 1rem 0rem 1rem;
    }

    .product_img img {
        width: 20rem;
    }

    .product2_img img {
        width: 20rem;
    }

    .product3_img img {
        width: 25rem;
    }

    .product4_img img {
        width: 24rem;
    }

    .product5_img img {
        width: 23rem;
    }
    
    .product6_img img {
        max-width: 20rem;
    }
    
    .product7_img img {
        max-width: 15rem;
    }

    .product8_img img {
        max-width: 23rem;
    }
}

@media (max-width: 428px) and (min-width: 375px) {
    .firstcontainer_products {
        padding: 8rem 1rem 3rem 1rem;
    }

    .firstcontainer_products h1 {
        font-size: 2.4rem;
        line-height: 2.6rem;
    }

    .firstcontainer_products p {
        font-size: 1.2rem;
    }

    .productlayout {
        flex-direction: column-reverse;
        padding: 1rem;
    }

    .text_blockproducts {
        margin-right: 0rem;
    }

    .text_blockproducts h1 {
        font-size: 2.2rem;
    }
    
    .text_blockproducts p {
        font-size: 1.1rem;
    }

    .productlayout_second {
        flex-direction: column;
        padding: 3rem 1rem 1rem 1rem;
    }

    .text_blockproducts_second {
        margin-left: 0rem;
    }

    .text_blockproducts_second h1 {
        font-size: 2.2rem;
    }
    
    .text_blockproducts_second p {
        font-size: 1.1rem;
    }

    .productlayout_third {
        flex-direction: column-reverse;
        padding: 3rem 1rem 1rem 1rem;
    }

    .text_blockproducts_third h1 {
        font-size: 2.2rem;
    }
    
    .text_blockproducts_third p {
        font-size: 1.1rem;
    }
    
    .productlayout_fifth {
        flex-direction: column;
        padding: 3rem 1rem 1rem 1rem;
    }

    .text_blockproducts_fifth {
        margin-left: 0rem;
    }

    .text_blockproducts_fifth h1 {
        font-size: 2.2rem;
    }
    
    .text_blockproducts_fifth p {
        font-size: 1.1rem;
    }

    .productlayout_fourth {
        flex-direction: column;
        padding: 3rem 1rem 1rem 1rem;
    }

    .text_blockproducts_fourth {
        margin-left: 0rem;
    }

    .text_blockproducts_fourth h1 {
        font-size: 2.2rem;
    }
    
    .text_blockproducts_fourth p {
        font-size: 1.1rem;
    }

    .productlayout_eight {
        padding: 5rem 1rem 0rem 1rem;
        flex-direction: column-reverse;
    }

    .text_blockproducts_eight h1 {
        font-size: 2.2rem;
    }
    
    .text_blockproducts_eight p {
        font-size: 1.1rem;
    }
    
    .title_sectionformen {
        padding: 4rem 1rem 0rem 1rem;
    }

    .title_sectionformen h1 {
        font-size: 2.2rem;
    }

    .product_img img {
        width: 18rem;
    }

    .product2_img img {
        width: 18rem;
    }

    .product3_img img {
        width: 22rem;
    }

    .product4_img img {
        width: 22rem;
    }

    .product5_img img {
        width: 21rem;
    }
    
    .product6_img img {
        max-width: 20rem;
    }
    
    .product7_img img {
        max-width: 15rem;
    }

    .product8_img img {
        max-width: 21rem;
    }
}