.first_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(360deg, #fff, rgba(255, 255, 255, 0.376) 256.61%), url('../../assets/img/slideimgtwo.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    font-family: 'arboria';
    color: #d19e78;
    overflow: hidden;
}

.first_container img {
    max-width: 23rem;
}

.first_home_block {
    display: flex;
    align-items: center;
    padding: 8rem 3rem 0rem 3rem;
}

.first_text_block h1 {
    font-size: 3.8rem;
    font-weight: 900;
    line-height: 4.3rem;
}

.first_text_block p {
    margin-top: 1rem;
    font-size: 1.3rem;
    font-weight: 400;
}

.second_text_block {
    display: flex;
    flex-direction: column;
    text-align: end;
}

.second_text_block h1 {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.6rem;
}

.word {
    text-transform: uppercase;
    font-weight: 900;
}

.second_text_block p {
    margin-top: 1rem;
    font-size: 1.3rem;
    font-weight: 400;
}

.home_block_links {
    display: flex;
    align-self: flex-end;
    margin-top: 2.6rem;
}

.home_first_btn:first-child button {
    background: none;
    color: #d19e78;
    width: 11rem;
    height: 3.4rem;
    font-family: 'arboria';
    font-size: 1.2rem;
    border-radius: 1rem;
    cursor: pointer;
    font-weight: 400;
    margin-right: 1rem;
    border: 1px solid #d19e78;
}

.home_second_btn button {
    background: linear-gradient(349.78deg, #fff 17.21%, #ede8de 116.61%);
    color: #d19e78;
    border: 1px solid #fff;
    width: 11rem;
    height: 3.4rem;
    font-family: 'arboria';
    font-size: 1.2rem;
    border-radius: 1rem;
    cursor: pointer;
    font-weight: 400;
    box-shadow: 1px 1px 3px #c9c5bd, -12px -12px 24px #ffffff;
}

.home_second_btn button:hover {
    background: linear-gradient(-149.78deg, #fff 17.21%, #ede8de 116.61%);
    transition: all 0.2s ease-in-out;
}

.home_first_btn button:hover {
    background: linear-gradient(-149.78deg, #fff, rgba(146, 146, 146, 0.301) 276.61%);
    transition: all 0.2s ease-in-out;
}

.second_container {
    background: radial-gradient(circle at top , #ede8de , #ffffff 40%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.title_second_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title_second_container img {
    max-width: 17rem;
    margin-top: 0rem;
    border: 0.16rem solid rgba(255, 255, 255, 0.2);
    padding: 1.1rem 2.6rem;
    border-radius: 0rem 0rem 3rem 3rem;
    border-top: none;
    background: linear-gradient(349.78deg, #fff -17.21%, #ede8de 116.61%);
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.1);
}

.title_second_container .title_container h1 {
    color: #d19e78;
    font-size: 3rem;
    font-family: 'arboria';
    padding: 2rem;
    text-align: center;
    font-weight: 700;
}

.acessibility_container {
    font-size: 0.9rem;
    font-family: 'arboria';
    color: #d19e78;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.acessibility_container img {
    max-width: 3rem;
    margin-top: 1rem;
}

.acessibility_container img:nth-child(1) {
    max-width: 4rem;
    margin-top: 2rem;
}

.container_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 1rem 2rem 1rem 2rem;
}

.item_box {
    max-width: 380px;
    max-height: 440px;
}

.item_box:nth-child(2) {
    margin-right: 3rem;
    margin-left: 3rem;
}

.item_box img {
    object-fit: cover;
    border-radius: 1rem;
}

.title_steps_list h1 {
    color: #d19e78;
    font-size: 3rem;
    font-family: 'arboria';
    padding: 2rem;
    text-align: center;
    font-weight: 700;
}

.list_box {
    display: flex;
    justify-content: center;
    padding: 1rem 0rem 3rem 0rem;
}

.list_box_one {
    display: flex;
}

.item_list {
    color: #d19e78;
    font-family: 'arboria';
    text-align: center;
    background: linear-gradient(360deg, #ede8de -17.21%, #fff 56.61%);
    border-radius: 1rem;
    padding: 1rem;
    max-width: 18rem;
    border: 0.16rem solid rgba(255, 255, 255, 0.2);
    box-shadow: 1px 1px 3px #c9c5bd, -3px -3px 6px #ffffff;
    margin-left: 1rem;
    margin-right: 1rem;
}

.item_list p {
    margin-top: 1rem;
    line-height: 1.4rem;
    font-size: 1rem;
    font-weight: 400;
}

.item_list h2 {
    font-size: 4rem;
    color: #d19e78;
    opacity: 0.8;
}

.item_list h3 {
    color: #d19e78;
    font-size: 1.4rem;
    letter-spacing: 0.03rem;
    font-weight: 900;
}

.buttoninvisible {
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttoninvisible button {
    background: linear-gradient(349.78deg, #fff -17.21%, #ede8de 116.61%);
    color: #d19e78;
    border: 1px solid #fff;
    width: 11rem;
    height: 3.4rem;
    font-family: 'arboria';
    font-size: 1.2rem;
    border-radius: 1rem;
    cursor: pointer;
    font-weight: 400;
    box-shadow: 1px 1px 3px #c9c5bd, -1px -1px 2px #ffffff;
    margin-bottom: 1rem;
}

.buttoninvisible button:hover {
    background: linear-gradient(-149.78deg, #fff 17.21%, #e0e0e0 116.61%);
    transition: all 0.2s ease-in-out;
}

.third_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem 3rem;
    background-image: radial-gradient(circle at bottom right, #ede8de 11%, #ffffff 23%);
    overflow: hidden;
}

.third_block {
    display: flex;
}

.third_text_block {
    font-size: 1.3rem;
    color: #d19e78;
    font-family: 'arboria';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 400;
}

.third_text_block p:nth-child(2) {
    margin-top: 1rem;
}

.third_text_block:nth-child(3) {
    text-align: end;
    text-transform: uppercase;
}

.container_imgft img {
    display: flex;
    max-width: 32rem;
}

.title_third_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #d19e78;
    font-family: 'arboria';
    text-align: center;
    font-weight: 700;
    padding: 2rem 12rem 0rem 12rem;
}

.title_third_container h1 {
    font-size: 3rem;
}

.title_third_container img {
    max-width: 13rem;
    margin-top: 2.3rem;
}

.third_container button {
    background: linear-gradient(349.78deg, #fff -17.21%, #ede8de 116.61%);
    color: #d19e78;
    border: 1px solid #fff;
    width: 11rem;
    height: 3.4rem;
    font-family: 'arboria';
    font-size: 1.2rem;
    border-radius: 1rem;
    cursor: pointer;
    margin-top: 3rem;
    font-weight: 400;
    box-shadow: 1px 1px 3px #c9c5bd, -1px -1px 2px #ffffff;
}

.third_container button:hover {
    background: linear-gradient(-149.78deg, #fff 17.21%, #ede8de 116.61%);
    transition: all 0.2s ease-in-out;
}

.fourth_container {
    background-image: linear-gradient(-160deg, #ede8de, #fff 35%);
    padding: 3rem 0rem 0rem 0rem;
}

.fourth_text {
    color: #cda582;
    font-family: 'arboria';
    text-align: center;
    padding: 0rem 10rem 1rem 10rem;
    overflow: hidden;
}

.fourth_text h1 {
    font-size: 3rem;
    font-weight: 700;
}

.slidercontainer {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
}

.swiper_container {
    height: 33rem;
}

.swiper-slide {
    width: 27rem !important;
    height: 29rem !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide-shadow {
    display: none;
}

.swiper-slide h1 {
    font-size: 1.2rem;
    font-weight: 400;
    font-family: 'arboria';
    color: #d19e78;
    background-image: linear-gradient(349.78deg, #fff -17.21%, #ede8de 116.61%);
    position: absolute;
    padding: 0.7rem 1rem 0.7rem 1.5rem;
    border-radius: 2rem 0rem 1rem 0rem;
}

.swiper-slide img {
    width: 27rem !important;
    height: 29rem !important;
    object-fit: cover;
    border-radius: 2rem;
}

.slider-controler .swiper-button-prev {
    color: #fff;
    top: 45%;
}

.slider-controler .swiper-button-next {
    color: #fff;
    top: 45%;
}

@media (max-width: 1024px) {
    .swiper_container {
        height: 33rem;
    }

    .swiper-slide {
        width: 26rem !important;
        height: 27rem !important;
    }

    .swiper-slide img {
        width: 26rem !important;
        height: 27rem !important;
    }
}

@media (max-width: 768px) {
    .swiper_container {
        height: 30rem;
    }

    .swiper-slide {
        width: 25rem !important;
        height: 26rem !important;
    }

    .swiper-slide img {
        width: 25rem !important;
        height: 26rem !important;
    }
}

@media (max-width: 500px) {
    .swiper_container {
        height: 25rem;
    }

    .swiper-slide {
        width: 18rem !important;
        height: 22rem !important;
    }

    .swiper-slide img {
        width: 18rem !important;
        height: 22rem !important;
    }
}

.buttonservice {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0 0 0;
}

.buttonservice button {
    background-image: linear-gradient(349.78deg, #fff -17.21%, #ede8de 116.61%);
    color: #d19e78;
    width: 15rem;
    height: 3.4rem;
    border: 1px solid #fff;
    font-family: 'arboria';
    font-size: 1.2rem;
    border-radius: 1rem;
    cursor: pointer;
    font-weight: 400;
    box-shadow: 1px 1px 3px #c9c5bd, -1px -1px 2px #ffffff;
}

.buttonservice button:hover {
    background: linear-gradient(-149.78deg, #fff 17.21%, #ede8de 116.61%);
    transition: all 0.2s ease-in-out;
}

.footer {
    background-image: linear-gradient(to top, #ede8de, #fff 75%);
    padding: 3rem 0 2rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.footer img {
    width: 10rem;
}

.footer_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(349.78deg, #fff -17.21%, #ede8de 116.61%);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    border-radius: 1.6rem;
    width: 5rem;
    height: 5rem;
    box-shadow: 1px 1px 3px #c9c5bd, -1px -1px 2px #ffffff;
    cursor: pointer;
}

.footer_icon i {
    font-size: 3.4rem;
    color: #d19e78;
}

@media (max-width: 1440px) and (min-width: 1376px) {
    .first_container img {
        max-width: 25rem;
    }

    .container_imgft img {
        max-width: 34rem;
    }
}

@media (max-width: 1115px) and (min-width: 1025px) {
    .first_container img {
        max-width: 22rem;
    }
    .first_text_block h1 {
        font-size: 3.6rem;
        line-height: 3.6rem;
    }
    
    .first_text_block p {
        font-size: 1.3rem;
    }

    .second_text_block p {
        font-size: 1.3rem;
    }

}

@media (max-width: 1024px) and (min-width: 768px) {
    .first_home_block {
        flex-direction: column;
        align-items: center;
        padding: 8rem 3rem 2rem 3rem;
    }

    .first_text_block h1 {
        text-align: center;
    }

    .first_text_block p {
        text-align: center;
    }

    .second_text_block {
        text-align: center;
    }

    .home_block_links {
        margin-top: 2rem;
        align-self: center;
    }

    .container_box {
        flex-direction: column;
        align-items: center;
    }

    .item_box {
        margin-top: 1rem;
    }

    .item_box:nth-child(2) {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    
    .list_box {
        flex-direction: column;
    }

    .list_box_one:nth-child(2) {
        margin-top: 2rem;
    }

    .third_container {
        padding: 0rem 3rem 2rem 3rem;
    }

    .title_third_container {
        padding: 3rem 1rem 0rem 1rem;
    }

    .third_block {
        flex-direction: column;
        align-items: center;
    }

    .third_text_block {
        text-align: center;
        margin-top: 1rem;
    }

    .container_imgft img {
        margin-top: 1rem;
    }
    
    .third_text_block:nth-child(3) {
        text-align: center;
    }

    .third_container button {
        margin-top: 2rem;
    }

    .fourth_text {
        padding: 0rem 2rem 1rem 2rem;
    }

}

@media (max-width: 767px) and (min-width: 590px) {
    .first_home_block {
        flex-direction: column;
        align-items: center;
        padding: 8rem 2rem 3rem 2rem;
    }

    .second_text_block {
        text-align: start;
    }

    .home_block_links {
        margin-top: 2rem;
        align-self: start;
    }

    .item_box:nth-child(2) {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .title_container {
        padding: 1rem;
    }

    .list_box {
        flex-direction: column;
    }

    .list_box_one:nth-child(2) {
        margin-top: 2rem;
    }
    
    .third_container {
        padding: 0rem 2rem 2rem 2rem;
    }

    .title_third_container {
        padding: 3rem 0rem 0rem 0rem;
    }

    .third_block {
        flex-direction: column;
        align-items: center;
    }

    .third_text_block {
        margin-top: 1rem;
    }

    .third_text_block p:nth-child(2) {
        margin-top: 1rem;
    }

    .third_text_block:nth-child(3) {
        text-align: start;
    }

    .fourth_text {
        padding: 0rem 2rem 1rem 2rem;
    }
}

@media (max-width: 589px) and (min-width: 429px) {
    .first_home_block {
        flex-direction: column;
        align-items: center;
        padding: 7rem 1rem 3rem 1rem;
    }

    .first_text_block h1 {
        font-size: 3.2rem;
        line-height: 3.2rem;
    }

    .first_text_block p {
        font-size: 1.2rem;
    }

    .second_text_block {
        text-align: start;
    }

    .second_text_block h1 {
        font-size: 2.3rem;
    }

    .second_text_block p {
        font-size: 1.2rem;
    }

    .home_block_links {
        margin-top: 2rem;
        align-self: start;
    }

    .second_container {
        flex-direction: column;
        align-items: center;
    }

    .title_second_container .title_container h1 {
        padding: 1rem 1rem 1.3rem 1rem;
        font-size: 2rem;
    }

    .container_box {
        padding: 1rem;
    }

    .item_box {
        max-width: 360px;
        max-height: 420px;
    }

    .item_box:nth-child(2) {
        margin-top: 2rem;
        margin-bottom: 2rem;
        margin-right: 0rem;
        margin-left: 0rem;
    }

    .title_steps_list h1 {
        padding: 1rem;
        font-size: 2rem;
    }

    .list_box {
        flex-direction: column;
        padding: 1rem 0rem 2rem 0rem;
    }

    .list_box_one {
        display: flex;
        flex-direction: column;
    }

    .item_list {
        margin-bottom: 1.6rem;
        margin-left: 0rem;
    }

    .third_container {
        padding: 0rem 1rem 2rem 1rem;
    }

    .title_third_container {
        text-align: start;
        padding: 3rem 0rem 0rem 0rem;
    }

    .title_third_container h1 {
        font-size: 2rem;
    }

    .third_block {
        flex-direction: column;
        align-items: center;
    }

    .third_text_block {
        margin-top: 1rem;
    }

    .third_text_block p {
        font-size: 1.2rem;
    }

    .third_text_block p:nth-child(2) {
        margin-top: 1rem;
    }

    .container_imgft img {
        max-width: 24rem;
    }

    .third_container button {
        margin-top: 2rem;
    }

    .fourth_text {
        padding: 0rem 1rem 1rem 1rem;
        text-align: start;
    }

    .fourth_text h1 {
        font-size: 2rem;
    }
}

@media (max-width: 428px) and (min-width: 375px) {
    .first_home_block {
        flex-direction: column;
        align-items: center;
        padding: 7rem 0rem 3rem 0rem;
    }

    .first_container img {
        max-width: 21rem;
    }

    .first_text_block {
        padding: 0 1rem;
    }

    .first_text_block h1 {
        font-size: 3rem;
        line-height: 3rem;
    }

    .first_text_block p {
        font-size: 1.2rem;
    }

    .second_text_block {
        text-align: start;
    }

    .second_text_block h1 {
        font-size: 2rem;
        line-height: 2.4rem;
        padding: 0 1rem;
    }

    .second_text_block p {
        font-size: 1.2rem;
        padding: 0 1rem;
    }

    .home_block_links {
        margin-top: 2rem;
        align-self: center;
    }

    .second_container {
        flex-direction: column;
        align-items: center;
    }

    .title_second_container .title_container h1 {
        padding: 1rem 1rem 1.3rem 1rem;
        font-size: 2rem;
        line-height: 2.4rem;
    }

    .acessibility_container {
        font-size: 0.8rem;
    }

    .container_box {
        padding: 1rem;
    }

    .item_box {
        max-width: 320px;
        max-height: 380px;
    }

    .item_box:nth-child(2) {
        margin-top: 2rem;
        margin-bottom: 2rem;
        margin-right: 0rem;
        margin-left: 0rem;
    }

    .title_steps_list h1 {
        padding: 1rem;
        font-size: 2rem;
        line-height: 2.4rem;
    }

    .list_box {
        flex-direction: column;
        padding: 1rem 0rem 2rem 0rem;
    }

    .list_box_one {
        display: flex;
        flex-direction: column;
    }

    .item_list {
        margin-bottom: 1.6rem;
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .third_container {
        padding: 0rem 0rem 2rem 0rem;
    }

    .title_third_container {
        text-align: start;
        padding: 3rem 1rem 0rem 1rem;
    }

    .title_third_container h1 {
        font-size: 2rem;
        line-height: 2.4rem;
    }

    .third_block {
        flex-direction: column;
        align-items: center;
    }

    .third_text_block {
        margin-top: 1rem;
        text-align: center;
    }

    .third_text_block p {
        font-size: 1.2rem;
        padding: 0 1rem;
    }

    .third_text_block p:nth-child(2) {
        margin-top: 1rem;
    }

    .third_text_block:nth-child(3) {
        margin-top: 0.5rem;
        text-align: center;
    }

    .container_imgft img {
        max-width: 24rem;
    }

    .third_container button {
        margin-top: 2rem;
    }

    .fourth_text {
        padding: 0rem 2rem 1rem 1rem;
        text-align: start;
    }

    .fourth_text h1 {
        font-size: 2rem;
        line-height: 2.4rem;
    }

}