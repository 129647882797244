.navigationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow_icon {
    cursor: pointer;
    position: fixed;
    bottom: 115px;
    right: 45px;
    z-index: 100;
}

.arrow_icon i {
    color: #d19e78;
    font-size: 3.4rem;
}


@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
    }

    80% {
        box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
    }
}

.wpp_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(349.78deg, rgb(2, 196, 73) -37.21%, rgb(0, 255, 94) 36.61%);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    border-radius: 3rem;
    width: 5.4rem;
    height: 5.4rem;
    box-shadow: 0 1.5rem 3.5rem rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: fixed;
    bottom: 30px;
    right: 20px;
    z-index: 100;
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}

.wpp_icon i {
    color: #fff;
    font-size: 3.4rem;
}