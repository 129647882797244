.title_stretches {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(358deg, #fff 20%, #ede8de 80%);
    padding: 8rem 9rem 0rem 9rem;
    color: #d19e78;
    font-family: 'arboria';
    text-align: center;
}

.title_stretches h1 {
    font-size: 2.8rem;
    font-weight: 700;
}

.text_containeris {
    color: #d19e78;
    font-family: 'arboria';
    display: flow-root;
    padding: 2rem 6rem 0rem 3rem;
    background-image: linear-gradient(12deg, #ede8de, #fff 36%);
}

.text_blockis {
    padding: 4rem 0rem 0rem 0rem;
}


.text_containeris img {
    width: 26rem;
    float: left;
    shape-outside: url(../../assets/img/layer.png);
    shape-margin: 2rem;
    margin-right: 2rem;
}

.text_containeris h1 {
    font-size: 2.8rem;
    font-weight: 700;
}

.text_containeris h3 {
    font-size: 1.8rem;
    margin-top: 2rem;
    text-decoration: underline;
}

.text_containeris h2 {
    text-transform: uppercase;
    margin-top: 2rem;
    font-size: 1.5rem;
    text-decoration: underline;
}

.text_containeris p {
    margin-top: 2rem;
    font-weight: 400;
    font-size: 1.3rem;
}

.secondcontainer_is {
    background: radial-gradient(circle at top, #ede8de, #ffffff 40%);
    padding: 0rem 3rem 0rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.secondcontainer_is img {
    max-width: 17rem;
    border: 0.16rem solid rgba(255, 255, 255, 0.2);
    padding: 1.1rem 2.6rem;
    border-radius: 0rem 0rem 3rem 3rem;
    border-top: none;
    background: linear-gradient(349.78deg, #fff -17.21%, #ede8de 116.61%);
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.1);
}

.block_title h1 {
    font-family: 'arboria';
    color: #d19e78;
    text-align: center;
    font-weight: 700;
    margin-top: 2rem;
    font-size: 2rem;
    text-transform: uppercase;
}

.box_questions {
    display: flex;
    justify-content: center;
}

.box_questions .questions .item_questions .content p {
    font-family: 'arboria';
    font-weight: 400;
}

.questions {
    display: flex;
    flex-direction: column;
    padding: 0rem 8rem 1rem 8rem;
}

.questions .item_questions {
    position: relative;
    background: linear-gradient(349.78deg, #fff -17.21%, #ede8de 116.61%);
    padding: 0.3rem 2rem 0.6rem 0rem;
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-top: 2.3rem;
}

.item_questions {
    font-family: 'arboria';
    color: #d19e78;
}

.item_questions h3 {
    font-weight: 500;
    font-size: 1.5rem;
}

.item_questions h3 input:checked {
    margin-top: 1rem;
}

.item_questions input {
    display: none;
}

.questions .item_questions label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.questions .item_questions label::after {
    content: '+';
    position: absolute;
    font-size: 2rem;
    bottom: 0.5rem;
    right: 20px;
    color: rgba(0, 0, 0, 0.2);
    transition: transform 1s;
}

.questions .item_questions:hover label::after {
    color: #d19e78;
}

.questions .item_questions input:checked~label::after {
    transform: rotate(135deg);
    bottom: 5rem;
}

.questions .item_questions label h2 {
    width: 40px;
    height: 40px;
    background: linear-gradient(349.78deg, #fff -17.21%, #ede8de 116.61%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #d19e78;
    font-size: 1.2rem;
    border-radius: 1rem 0rem 1rem 0rem;
    margin-right: 10px;
}

.questions .item_questions .content {
    max-height: 0;
    transition: 1s;
    overflow: hidden;
}

.questions .item_questions input:checked~.content {
    max-height: 100vh;
}

.questions .item_questions .content p {
    font-family: 'arboria';
    color: #d19e78;
    font-size: 1.1rem;
    position: relative;
    padding: 1rem 2rem 1rem 1rem;
    z-index: 10;
}

.third_containeris {
    padding: 3rem 12rem 2rem 12rem;
}

.third_containeris h1 {
    font-family: 'arboria';
    color: #cda582;
    text-align: center;
    font-weight: 700;
    font-size: 2.8rem;
}

.curly_title_container {
    padding: 1rem 0rem;
    color: #cda582;
    font-family: 'arboria';
    text-align: center;
}

.curly_title_container h1 {
    font-size: 2.8rem;
    font-weight: 700;
    margin-top: 3rem;
}

.curly_container {
    color: #d19e78;
    font-family: 'arboria';
    display: flow-root;
    padding: 0rem 5rem 0rem 5rem;
    background-image: linear-gradient(310deg, #ede8de, #fff 26%);
}

.block_curly {
    padding: 6rem 0rem 0rem 0rem;
}

.curly_container img {
    max-width: 26rem;
    float: right;
    shape-outside: url(../../assets/img/cacheado.png);
    margin-right: 1rem;
}

.curly_container h1 {
    font-size: 2.8rem;
    font-weight: 900;
}

.curly_container h2 {
    font-size: 2.4rem;
    margin-top: 3rem;
}

.curly_container p {
    margin-top: 2rem;
    font-weight: 400;
    font-size: 1.3rem;
}

.titledreams_container {
    color: #d19e78;
    font-family: 'arboria';
    text-align: center;
    padding: 2rem 12rem 2rem 12rem;
    background-image: linear-gradient(190deg, #ede8de, #fff 26%);
}

.titledreams_container img {
    max-width: 10rem;
}

.titledreams_container h2 {
    margin-top: 1rem;
    font-size: 2.4rem;
}

.dreams_container {
    display: flex;
    padding: 0rem 5rem 0rem 5rem;
    align-items: center;
}

.block_dreams {
    color: #d19e78;
    font-family: 'arboria';
    padding: 0rem 0rem 0rem 3rem;
}

.block_dreams h1 {
    font-size: 2.6rem;
    font-weight: 700;
}

.block_dreams p {
    margin-top: 2rem;
    font-weight: 400;
    font-size: 1.3rem;
}

.dreams_container img {
    width: 18rem;
}

.dreams_result {
    display: flex;
    padding: 0rem 5rem 2rem 5rem;
    justify-content: center;
    align-items: center;
}

.dreamsresult_block {
    color: #d19e78;
    font-family: 'arboria';
    margin-right: 3rem;
}

.dreamsresult_block h1 {
    font-size: 2.6rem;
    font-weight: 700;
}

.dreamsresult_block p {
    font-size: 1.3rem;
    margin-top: 2rem;
    font-weight: 400;
}

.dreams_result img {
    width: 400px;
    border-radius: 2rem;
}

.buttonschedulingtwo {
    display: flex;
    justify-content: center;
    padding: 0rem 0rem 3rem 0rem
}

.buttonschedulingtwo a {
    position: absolute;
    background: linear-gradient(349.78deg,  rgb(2, 196, 73)  -17.21%, rgb(0, 255, 94) 116.61%);
    color: #fff;
    font-family: 'arboria';
    border: 1px solid #fff;
    font-size: 1.4rem;
    cursor: pointer;
    font-weight: 500;
    box-shadow: 1px 1px 3px #c9c5bd, -1px -1px 2px #ffffff;
    text-decoration: none;
    padding: 18px 28px;
    text-transform: uppercase;
    overflow: hidden;
}

.buttonschedulingtwo a:hover {
    background: linear-gradient(-149.78deg, rgb(0, 255, 94) 17.21%, rgb(2, 196, 73) 116.61%);
    transition: all 0.2s ease-in-out;
}

.buttonschedulingtwo a div:nth-child(1) {
	position: absolute;
    width: 100%;
	height: 3px;
	top: 0;
	left: 0;
	background: linear-gradient(to right, rgb(0, 255, 94) 10%, rgb(2, 196, 73) , #fff);
    animation: animate1 2s linear infinite;
    overflow: hidden;
}

@keyframes animate1{
	0%{
		transform: translateX(-100%);
	}
	100%{
		transform: translateX(100%);
	}
}

a div:nth-child(2) {
	position: absolute;
	width: 3px;
	height: 100%;
	top: 0;
	right: 0;
	background: linear-gradient(160deg, rgb(2, 196, 73) 10%, rgb(0, 255, 94), #fff);
	animation: animate2 2s linear infinite;
	animation-delay: 1s;
    overflow: hidden;
}

@keyframes animate2{
	0%{
		transform: translateY(-100%);
	}
	100%{
		transform: translateY(100%);
	}
}

a div:nth-child(3) {
	position: absolute;
	width: 100%;
	height: 3px;
	left: 0;
	bottom: 0;
	background: linear-gradient(160deg, rgb(0, 255, 94) 10%, rgb(2, 196, 73) , #fff);
	animation: animate3 2s linear infinite;
    overflow: hidden;
}
@keyframes animate3{
	0%{
		transform: translateX(100%);
	}
	100%{
		transform: translateX(-100%);
	}
}
a div:nth-child(4) {
	position: absolute;
	width: 3px;
	height: 100%;
	top: 0;
	left: 0;
	background: linear-gradient(160deg, rgb(2, 196, 73)  10%, rgb(0, 255, 94) , #fff);
	animation: animate4 2s linear infinite;
	animation-delay: 1s;
    overflow: hidden;
}
@keyframes animate4{
	0%{
		transform: translateY(100%);
	}
	100%{
		transform: translateY(-100%);
	}
}



@media (max-width: 1115px) and (min-width: 1024px) {
    .text_containeris {
        padding: 2rem 2rem 0rem 2rem;
    }

    .text_blockis p:nth-child(5) {
        display: none;
    }

    .curly_container {
        padding: 0rem 0rem 0rem 2rem;
    }

    .curly_container h2 {
        margin-top: 1rem;
    }

    .curly_container p {
        margin-top: 1rem;
    }

    .titledreams_container {
        padding: 2rem 6rem;
    }

    .dreams_container {
        padding: 0rem 3rem 2rem 3rem;
    }

    .dreams_result {
        padding: 0rem 3rem 2rem 3rem;
    }
}

@media (max-width: 1023px) and (min-width: 768px) {
    .title_stretches {
        padding: 8rem 3rem 0rem 3rem;
    }

    .title_stretches h1 {
        line-height: 3rem;
    }

    .text_containeris {
        padding: 2rem 1rem 0rem 1rem;
    }

    .text_containeris img {
        max-width: 24rem;
        shape-margin: 1rem;
        margin-right: 1rem;
    }

    .text_containeris h3 {
        display: none;
    }

    .text_blockis p:nth-child(5) {
        display: none;
    }

    .questions {
        padding: 0rem;
    }

    .item_questions {
        font-size: 1.3rem;
    }

    .third_containeris {
        padding: 3rem 3rem 2rem 3rem;
    }

    .curly_container {
        padding: 0rem 2rem 0rem 2rem;
    }

    .curly_container img {
        margin-right: 0rem;
    }

    .curly_container h1 {
        font-size: 2.5rem;
    }

    .curly_container h2 {
        font-size: 2rem;
        margin-top: 2rem;
        line-height: 2rem;
    }

    .curly_container p:nth-child(3) {
        display: none;
    }

    .titledreams_container {
        padding: 3rem;
    }

    .dreams_container {
        padding: 0rem 3rem 2rem 3rem;
        flex-direction: column;
        text-align: center;
    }

    .block_dreams {
        padding: 1rem 0rem 0rem 0rem;
    }

    .dreams_result {
        flex-direction: column-reverse;
        padding: 0rem 3rem 2rem 3rem;
        text-align: center;
    }

    .dreamsresult_block {
        margin-top: 1rem;
    }
}

@media (max-width: 767px) and (min-width: 590px) {
    .title_stretches {
        padding: 8rem 0rem 0rem 0rem;
    }

    .text_containeris {
        padding: 2rem 1rem 0rem 1rem;
    }

    .text_containeris img {
        max-width: 23rem;
        shape-margin: 1rem;
        margin-right: 1rem;
    }

    .text_containeris h1 {
        font-size: 2.4rem;
        line-height: 2.4rem;
    }

    .text_containeris h2 {
        display: none;
    }

    .text_containeris p {
        margin-top: 1rem;
        font-weight: 400;
        font-size: 1.3rem;
    }

    .text_blockis p:nth-child(4) {
        display: none;
    }

    .questions {
        padding: 0rem;
    }

    .item_questions {
        font-size: 1.3rem;
    }

    .third_containeris {
        padding: 3rem 1rem 2rem 1rem;
    }

    .curly_container {
        padding: 0rem 1rem 0rem 1rem;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .block_curly {
        padding: 2rem 0rem 0rem 0rem;
    }

    .curly_container h2 {
        margin-top: 1rem;
    }

    .block_curly p:nth-child(3) {
        display: none;
    }

    .titledreams_container {
        padding: 3rem;
    }

    .dreams_container {
        padding: 0rem 2rem 2rem 2rem;
        flex-direction: column;
        text-align: center;
    }

    .block_dreams {
        padding: 1rem 0rem 0rem 0rem;
    }

    .dreams_result {
        flex-direction: column-reverse;
        padding: 0rem 2rem 2rem 2rem;
        text-align: center;
    }

    .dreamsresult_block {
        margin-top: 1rem;
    }
}

@media (max-width: 589px) and (min-width: 429px) {
    .title_stretches {
        padding: 8rem 1rem 0rem 1rem;
        text-align: start;
    }

    .title_stretches h1 {
        font-size: 2.4rem;
        line-height: 2.6rem;
    }

    .text_containeris {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 1rem 2rem 1rem;
    }

    .text_containeris img {
        max-width: 23rem;
        margin-right: 0rem;
    }

    .text_blockis {
        padding: 0rem;
    }

    .text_containeris h1 {
        font-size: 2.6rem;
        line-height: 2.6rem;
    }

    .text_containeris h2 {
        display: none;
    }

    .text_containeris p {
        margin-top: 1rem;
    }

    .text_blockis p:nth-child(5) {
        display: none;
    }

    .secondcontainer_is {
        padding: 0rem 1rem 0rem 1rem;
    }

    .questions {
        padding: 0rem;
    }

    .item_questions {
        font-size: 1.2rem;
    }

    .questions .item_questions label::after {
        right: 10px;
    }

    .third_containeris {
        padding: 3rem 1rem 2rem 1rem;
    }

    .third_containeris h1 {
        text-align: start;
        font-size: 2.4rem;
        line-height: 2.6rem;
    }

    .curly_container {
        padding: 0rem 1rem 0rem 1rem;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .block_curly {
        padding: 2rem 0rem 0rem 0rem;
    }

    .curly_container h2 {
        font-size: 2.4rem;
        line-height: 2.6rem;
        margin-top: 1rem;
    }

    .block_curly p:nth-child(3) {
        display: none;
    }

    .curly_container img {
        max-width: 25rem;
        margin-right: 0rem;
    }

    .titledreams_container {
        padding: 1rem;
    }

    .titledreams_container h2 {
        font-size: 2.4rem;
        line-height: 2.6rem;
    }

    .dreams_container {
        padding: 1rem 1rem 2rem 1rem;
        flex-direction: column;
        text-align: center;
    }

    .block_dreams {
        text-align: start;
        padding: 1rem 0rem 0rem 0rem;
    }

    .block_dreams h1 {
        font-size: 2.4rem;
        line-height: 2.6rem;
    }

    .dreams_result {
        flex-direction: column-reverse;
        padding: 0rem 1rem 2rem 1rem;
        text-align: start;
    }

    .dreamsresult_block {
        margin-top: 1rem;
    }
}

@media (max-width: 428px) and (min-width: 375px) {
    .title_stretches {
        padding: 8rem 1rem 0rem 1rem;
    }

    .title_stretches h1 {
        font-size: 2rem;
        line-height: 2.2rem;
    }

    .text_containeris {
        display: flex;
        flex-direction: column;
        padding: 2rem 1rem 2rem 1rem;
    }

    .text_containeris img {
        width: 21rem;
        margin-right: 0rem;
    }

    .text_blockis {
        padding: 0rem;
    }

    .text_containeris h1 {
        font-size: 2.2rem;
        text-align: center;
    }

    .text_containeris h3 {
        font-size: 1.5rem;
        margin-top: 1rem;
        text-align: center;
    }

    .text_containeris h2 {
        margin-top: 1.6rem;
        font-size: 1.2rem;
        text-align: center;
    }

    .text_containeris p {
        margin-top: 1.6rem;
        font-size: 1.2rem;
        text-align: center;
    }

    .text_blockis p:nth-child(5) {
        display: none;
    }

    .secondcontainer_is {
        padding: 0rem 1rem 0rem 1rem;
    }

    .questions {
        padding: 0rem;
    }

    .item_questions h3 {
        font-size: 1.3rem;
    }

    .questions .item_questions label::after {
        right: 5px;
        bottom: 1rem;
    }

    .questions .item_questions .content p {
        font-size: 1.1rem;
    }

    .third_containeris {
        padding: 3rem 1rem 2rem 1rem;
    }

    .third_containeris h1 {
        font-size: 2rem;
        line-height: 2.2rem;
    }

    .curly_container {
        padding: 0rem 1rem 0rem 1rem;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .block_curly {
        padding: 2rem 0rem 0rem 0rem;
        text-align: center;
    }

    .curly_container h1 {
        font-size: 2.6rem;
    }

    .curly_container h2 {
        font-size: 2rem;
        margin-top: 1.3rem;
    }

    .curly_container p {
        font-size: 1.2rem;
    }

    .block_curly p:nth-child(3) {
        display: none;
    }

    .curly_container img {
        max-width: 22rem;
        margin-right: 0rem;
        margin-top: 1rem;
    }

    .titledreams_container {
        padding: 2rem 1rem 0rem 1rem;
    }

    .titledreams_container h2 {
        font-size: 2rem;
    }

    .dreams_container {
        padding: 1rem 1rem 2rem 1rem;
        flex-direction: column;
    }

    .block_dreams {
        padding: 0.6rem 0rem 0rem 0rem;
        text-align: center;
    }

    .block_dreams h1 {
        font-size: 2rem;
        line-height: 2.2rem;
    }

    .block_dreams p {
        font-size: 1.2rem;
    }

    .block_dreams p:nth-child(3) {
        display: none;
    }

    .dreams_result {
        flex-direction: column-reverse;
        padding: 0rem 1rem 2rem 1rem;
    }

    .dreamsresult_block {
        margin-top: 1rem;
        text-align: center;
        margin-right: 0rem;
    }

    .dreamsresult_block h1 {
        font-size: 2rem;
        line-height: 2.2rem;
    }

    .dreamsresult_block p {
        font-size: 1.2rem;
        margin-top: 2rem;
    }

    .dreams_result img {
        width: 360px;
    }
}