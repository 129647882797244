.franchisetitlecontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(150deg,#e4c653 ,#fff  36%);
    padding: 8rem;
}

.franchisetitlecontainer h1 {
    color: #e4c653;
    font-size: 48px;
    font-family: 'Montserrat';
    text-align: center;
    max-width: 60rem;

}